import Trainer1 from "../images/jayprakash.jpg";
import Trainer2 from "../images/rajpatwa.jpg";
import { imageData } from "../images/img_index";
export const trainerDetail = [
  {
    id: 1,
    name: "Jayprakash Dhakad",
    phoneNumber: "+91 32939384",
    img: Trainer1,
    para1:
      "This column takes up 9/12 of the width on extra-large screens. This column takes up 9/12 of the width on extra-large",
    para2:
      "This column takes up 9/12 of the width on extra-large screens. This column takes up 9/12 of the width on extra-large screens. This column takes up 9/12 of the width on extra-large screens. This column takes up 9/12 of the width on extra-large screens.",
    specially: "conclusion",
    certification: "certification",
    Experience: "5+, sleft practice",
    flex: 80,
    skill: 70,
    access: 80,
  },
  {
    id: 2,
    name: "Raj Patwa",
    phoneNumber: "+91 32939384",
    img: Trainer2,
    para1:
      "This column takes up 9/12 of the width on extra-large screens. This column takes up 9/12 of the width on extra-large",
    para2:
      "This column takes up 9/12 of the width on extra-large screens. This column takes up 9/12 of the width on extra-large screens. This column takes up 9/12 of the width on extra-large screens. This column takes up 9/12 of the width on extra-large screens.",
    specially: "conclusion",
    certification: "certification",
    Experience: "4+, sleft practice",
    flex: 85,
    skill: 77,
    access: 90,
  },
  {
    id: 3,
    name: "Rohan Prajapati",
    phoneNumber: "+91 32939384",
    img: Trainer2,
    para1:
      "This column takes up 9/12 of the width on extra-large screens. This column takes up 9/12 of the width on extra-large",
    para2:
      "This column takes up 9/12 of the width on extra-large screens. This column takes up 9/12 of the width on extra-large screens. This column takes up 9/12 of the width on extra-large screens. This column takes up 9/12 of the width on extra-large screens.",
    specially: "conclusion",
    certification: "certification",
    Experience: "4+, sleft practice",
    flex: 87,
    skill: 80,
    access: 99,
  },
];


export const eevent = [
  {
    id: 1,
    eventName: "",
    heading: 'Classes',
    title:`Best Yoga Classes In Andheri West | Arogya Yoghome LLP`,
    keyboards:`Best Yoga Classes In Andheri  West`,
    slugg:`best-yoga-classes-andheri-west`,
    description:`Arogya Yoghome LLP offers the Best Yoga Classes in Andheri West, providing personalized sessions to enhance flexibility, strength, and mindfulness.`,
    eventData: [
      {
        id: 1,
        image: imageData.HomeYoga,
        type: "Time Flexibility",
        link: "#",
        date: "14 Feb",
        slug: '/homeclasses',
        title: "Home/Personal Yoga Classes",
        duration: "50 min / Session",
        registrationDate: "08-11-2023",
        price: "$240 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 2,
        image: imageData.CommunityYoga,
        type: "Empower Communities",
        link: "event-style-1.html",
        date: "14 Feb",
        title: "Society Yoga Classes",
        slug: '/societyclasses',
        duration: "55 min / Session",
        registrationDate: "22-11-2023",
        price: "$300 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 3,
        image: imageData.CorporateYoga,
        type: "Stress Buster",
        link: "event-style-1.html",
        date: "14 Feb",
        title: "Corporate Yoga",
        slug: '/corporateclasses',
        duration: "60 min / Session",
        registrationDate: "20-11-2023",
        price: "$330 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 4,
        image: imageData.Women_Yoga,
        type: "Women's special yoga",
        link: "event-style-1.html",
        date: "14 Feb",
        title: "Women's Care Yoga Classes",
        slug: '/womensclasses',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 5,
        image: imageData.F_C_Yoga,
        type: "Deepened Connections",
        link: "#",
        date: "14 Feb",
        title: "Family/Couple Yoga Classes",
        slug: '/familyclasses',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 6,
        image: imageData.EmpYoga,
        type: "Corporate Wellbeing System",
        link: "#",
        date: "14 Feb",
        title: "Office Yoga Classes",
        slug: '/officesclasses',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },

      {
        id: 7,
        image: imageData.AbroadYoga,
        type: "Yoga Around The World",
        link: "#",

        date: "14 Feb",
        title: "Yoga for Abroad",
        slug: '/abroadclasses',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },

      {
        id: 8,
        image: imageData.Occupation_Yoga,
        type: "Word related Occasion",
        link: "#",

        date: "14 Feb",
        title: "Occupational Yoga Classes",
        slug: '/occupationclasses',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },

      {
        id: 9,
        image: imageData.Occupation_Yoga,
        type: "Word related Occasion",
        link: "#",

        date: "14 Feb",
        title: "Resort & tourist yoga",
        slug: '/resorttourist',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 10,
        image: imageData.Occupation_Yoga,
        type: "Word related Occasion",
        link: "#",

        date: "14 Feb",
        title: " On-line yoga classes ",
        slug: '/onlinyoga',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
    ],
    para1: `At Arogya Yoghome, we’re excited to offer online yoga classes in Andheri that bring the benefits of yoga directly to your home, whether you're near or abroad. Our online classes cater to all skill levels, allowing you to practice at your own pace. With expert guidance from certified instructors, you’ll receive personalized attention to build strength, improve flexibility, and enhance your well-being. Our platform provides a safe, supportive space to grow your practice from the comfort of your home.
`,
    para2: `In addition to traditional yoga practices, we offer antinatal and postnatal yoga programs tailored to support women through pregnancy and after childbirth. Antinetal Yoga focuses on preparing the body for labor, reducing stress, and relieving common pregnancy discomforts. Postnatal   helps new mothers regain strength, improve posture, and restore emotional balance during the postpartum period.`,
    para3: `For those seeking more personalized instruction, Arogya Yoghome also offers personal training at home or one-on-one yoga sessions from Bandra to Borivali. Whether you prefer a private session in your own space or need focused guidance to deepen your practice, our certified trainers are here to provide customized attention to help you achieve your goals.`,
    para4: `Our online yoga classes in Andheri are designed to fit into your busy lifestyle. We offer a variety of styles, including Hatha Yoga, Vinyasa Flow, Pranayama, and specialized prenatal and postnatal sessions, allowing you to choose what suits your goals. Each session is carefully planned to promote physical fitness, mental relaxation, and emotional well-being. With the flexibility of online learning, you can practice yoga at a time that works best for you, easily fitting it into your routine.`,
    para5: `When you join our online yoga classes in Andheri, you’re joining a vibrant community, and we believe yoga is a powerful tool for personal growth, healing, and transformation. With consistent practice, you’ll experience enhanced flexibility, reduced stress, and mental clarity. Start your yoga journey with us today and discover the convenience and benefits of online yoga and personal training, including anti-natal and postnatal yoga, for everyone, near and far.`,

  },
  {
    id: 2,
    eventName: "",
    heading: 'Expert Consultation',
    keyboards:`Expert Consultation`,
    description:`Arogya Yoghome LLP offers expert Consultation in Andheri West, providing personalized healthcare solutions for your wellness needs.`,
    title:`Expert Consultation In Andheri West | Arogya Yoghome LLP`,
    slugg:`Expert-consultation-andheri-west`,

    eventData: [
      {
        id: 1,
        image: imageData.expert1,
        type: "Mobility",
        link: "#",
        date: "14 Feb",
        title: "Orthopedic Consultation",
        slug: '/orthopedic',
        slug2: ``,
        duration: "50 min / Session",
        registrationDate: "08-11-2023",
        price: "$240 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 2,
        image:imageData.expert2,
        type: "Physical",
        link: "event-style-1.html",

        date: "14 Feb",
        title: "Physician Consultation",
        slug: '/physician',
        duration: "55 min / Session",
        registrationDate: "22-11-2023",
        price: "$300 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 3,
        image:imageData.expert3,
        type: "Mental Well-Being",
        link: "event-style-1.html",

        date: "14 Feb",
        title: "Corporate Consultation",
        slug: '/corporate',
        duration: "60 min / Session",
        registrationDate: "20-11-2023",
        price: "$330 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 4,
        image:imageData.expert4,
        type: "Holistic Wellness",
        link: "#",

        date: "14 Feb",
        title: "Yoga Expert Consultation",
        slug: '/yoga-expert',
        duration: "45 min / Session",
        registrationDate: "14-11-2023",
        price: "$200 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 5,
        image:imageData.expert5,
        type: "Inner Fire",
        link: "event-style-1.html",

        date: "14 Feb",
        title: "Motivational Consultation",
        slug: '/motivational',
        duration: "55 min / Session",
        registrationDate: "11-11-2023",
        price: "$370 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 6,
        image: imageData.expert6,
        type: "Support",
        link: "event-style-1.html",

        date: "14 Feb",
        title: "Counsellor Consultation",
        slug: '/counsellor',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
    ],
    para1:`Expert Consultation for Yoga and Mental Health at Arogya Yoghome`,
    para2:`At Arogya Yoghome, we understand the deep connection between mental and physical health. Our Yoga for Mental Health in Andheri program is designed to address emotional and psychological well-being through personalized yoga practices. Whether you are dealing with stress, anxiety, depression, anti-natal and postnatal  \ or simply looking to enhance your overall mental clarity, our expert yoga instructors work closely with healthcare professionals to create a holistic approach to your mental health. By incorporating mindful movement, breathwork, and meditation, we help you find balance and emotional peace.`,
    para3:`Our Yoga for Mental Health in Andheri program combines the ancient wisdom of yoga with modern therapeutic techniques. This unique approach aims to reduce stress, improve mood, and boost mental resilience. Through personalised consultations and targeted yoga sessions, we guide you through techniques that promote mental calmness and emotional release. During each consultation, we assess your individual needs and develop a tailored plan to address your mental health challenges, ensuring that you receive the most effective care and support.`,
    para4:`When you choose yoga for mental health in Andheri, you’ll experience the transformative benefits of a holistic approach to wellness. Our sessions are designed to help you manage stress, alleviate anxiety, and improve mental clarity through yoga practices that promote relaxation and mindfulness. Whether you are looking for short-term relief or a long-term solution to mental wellness, our consultations provide you with the tools and guidance needed to build a healthier mindset and improve emotional well-being.`,
    para5:`Start your mental health journey today with Yoga for Mental Health at Arogya Yoghome and experience the healing power of yoga for your mind and body.`,
  },
  {
    id: 3,
    eventName: "",
    keyboards:`Types Of Yoga`,
    heading: 'Yoga Type',
    title:`Yoga In AndheriWest | Arogya Yoghome LLP`,
    description:`Experience holistic wellness at Arogya Yoghome LLP, offering expert-led yoga in Andheri West for improved mind-body balance.`,
    slugg:`yoga-andheri-west`,
    eventData: [
      {
        id: 1,
        image:imageData.type1,
        type: "Mobility",
        link: "#",
        date: "14 Feb",
        title: "Yoga Therapy",
        slug: '/yogathreapy',
        slug2: ``,
        duration: "50 min / Session",
        registrationDate: "08-11-2023",
        price: "$240 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 2,
        image:imageData.type2,
        type: "Physical",
        link: "event-style-1.html",

        date: "14 Feb",
        title: " Vinyasa flow",
        slug: '/vinyasaflow',
        duration: "55 min / Session",
        registrationDate: "22-11-2023",
        price: "$300 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 3,
        image:imageData.type3,
        type: "Mental Well-Being",
        link: "event-style-1.html",

        date: "14 Feb",
        title: "Traditional Yoga",
        slug: '/traditional_yoga',
        duration: "60 min / Session",
        registrationDate: "20-11-2023",
        price: "$330 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 4,
        image:imageData.type4,
        type: "Holistic Wellness",
        link: "#",

        date: "14 Feb",
        title: "Ashtanga Yoga",
        slug: '/ashtangayoga ',
        duration: "45 min / Session",
        registrationDate: "14-11-2023",
        price: "$200 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 5,
        image:imageData.type5,
        type: "Inner Fire",
        link: "event-style-1.html",

        date: "14 Feb",
        title: "Hatha yoga ",
        slug: '/hatha_yoga',
        duration: "55 min / Session",
        registrationDate: "11-11-2023",
        price: "$370 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 6,
        image:imageData.type6,
        type: "Support",
        link: "event-style-1.html",

        date: "14 Feb",
        title: "Power Yoga ",
        slug: '/power_yoga ',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 7,
        image:imageData.type7,
        type: "Support",
        link: "event-style-1.html",

        date: "14 Feb",
        title: "Raja yoga",
        slug: '/raja_yoga',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 8,
        image:imageData.type8,
        type: "Support",
        link: "event-style-1.html",

        date: "14 Feb",
        title: "Chair yoga, wheel yoga",
        slug: '/chair_yoga',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 9,
        image:imageData.type9,
        type: "Support",
        link: "event-style-1.html",

        date: "14 Feb",
        title: "Strap band and blocks yoga",
        slug: '/strap',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },

      {
        id: 10,
        image:imageData.type10,
        type: "Support",
        link: "event-style-1.html",

        date: "14 Feb",
        title: "Competitive yogasana",
        slug: '/competitiveyogasana',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },
      {
        id: 11,
        image:imageData.type11,
        type: "Support",
        link: "event-style-1.html",

        date: "14 Feb",
        title: "Prana Healing Yoga",
        slug: '/pranahealingyoga',
        duration: "30 min / Session",
        registrationDate: "01-11-2023",
        price: "$245 / Month",
        descp: `e It’s time to roll out your yoga mat and discovers combinations of physically and mentally exercises be While most people associate yoga with stretching and flexibility, some considered strength-building types of yoga classes can also be. It just depends on the class level, approach, and teacher. This makes yoga asana a multimodal form of exercise`,
        descp2: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of the evidence so far supports what practitioners seem to have known for millennia: Yoga is incredibly beneficial to our overall well-being. Flexibility is an important component of physical health. Yoga offers many styles to choose from, varying in intensity from high to moderate to mild. Even the lowest and intensity styles have been found to increase flexibilitySo, it makes sense that the second most cited reason people selected as to why they do yoga was to relieve stress`,
        descp3: `The scientific research into yoga’s benefits is still somewhat preliminary, but much of`,
      },


    ],
    para1:`At Arogya Yoghome, we offer a wide range of yoga practices designed to promote physical, mental, and emotional well-being. Whether you’re looking to enhance flexibility, build strength, do antinatal and postnatal yoga, or find inner peace, our classes cater to all levels. One of our signature offerings is Stress Relief Meditation in Andheri, a practice that helps you manage the stresses of daily life through focused mindfulness and breathwork. These sessions are carefully designed to help you achieve a calm, centred mind and body, making them ideal for those seeking stress reduction and relaxation.`,
    para2:`Incorporating stress relief meditation in Andheri into your yoga practice can provide powerful mental health benefits. Our meditation-focused yoga sessions focus on breathing techniques, body awareness, and deep relaxation to reduce anxiety and promote a sense of tranquillity. With guidance from our experienced instructors, you'll learn to release mental tension, improve focus, and cultivate mindfulness. Whether you're dealing with work stress, emotional fatigue, or general life pressures, our meditation practices are perfect for helping you reset and recharge.`,
    para3:`At Arogya Yoghome, we believe that stress relief meditation in Andheri should be a cornerstone of your wellness journey. Our meditation classes are designed to complement your physical yoga practice, offering an opportunity to go beyond the mat and connect with your inner self. Through consistent practice, you’ll experience deeper emotional balance, mental clarity, and a greater sense of peace in everyday life. Our meditation sessions can help you develop resilience and calm, so you can better navigate life's challenges with a balanced perspective`,
    para4:`We also designed kids' yoga classes that combine fun, creativity, and mindfulness. These sessions help children improve flexibility, strength, coordination, and focus, while also promoting emotional well-being`,
    para5:`Our Stress Relief Meditation classes are tailored to meet your personal needs and help you experience long-term benefits. Whether you’re new to meditation or have been practicing for years, we offer a supportive, welcoming environment to deepen your practice and enhance your overall well-being.`,
  },
];
