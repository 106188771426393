import React from "react";

import {
  FaFacebook,
  FaYoutube,
  FaWhatsapp,
  FaTwitter,
  FaLinkedin,
  FaInstagram,
  FaFacebookF,
  FaPhone,
  FaMapPin,
} from "react-icons/fa";
import { CgMail } from "react-icons/cg";

import { Link, useNavigate, } from "react-router-dom";

import "./Footer.css";
import { imageData } from "../images/img_index";

export default function Footer() {
  const navigaton = useNavigate();
  return (
    <>
      <section className="bottom-bar-cont">
        <div className="bar" />
        <div className="container">
          <div className="footer-row">
            <div className="footer-col-6">
              <h3 style={{ paddingTop: 5 }}>Abouts</h3>
              <p>
                Welcome to Yoga Home, where we understand the challenges of
                modern lifestyles and their impact on physical and emotional
                well-being.
              </p>
              <div className=" logo-cont">
               <Link onClick={() => window.screenTop(0,0)} to={'/'}> <img src={imageData.logo} /></Link>
              </div>
              <div style={{ display: 'none' }}>
                <span>reactnativeamalgum</span>
              </div>
            </div>
            <div className="footer-nav-cont  col-2">
              <h3>General</h3>
              <p>
                <Link onClick={() => window.screenTop(0, 0)} className="footer-nav-cont-p" to="/">
                  Home
                </Link>
              </p>
              <p>
                <Link onClick={() => window.screenTop(0, 0)} className="footer-nav-cont-p" to="/event/best-yoga-classes-andheri-west">
                  Services
                </Link>
              </p>
              <p>
                <Link onClick={() => window.screenTop(0, 0)} className="footer-nav-cont-p" to="/yoga-class-near-andheri">
                  About Us
                </Link>
              </p>
              <p>
                <Link onClick={() => window.screenTop(0, 0)} className="footer-nav-cont-p" to="/yoga-centre-andheri">
                  Contact Us
                </Link>
              </p>
            </div>
            <div className="footer-nav-cont col-2">
              <h3>Connect</h3>
              <Link to={'https://www.facebook.com/sohum.yoghome'} className="social">

                <FaFacebookF color="white" className="icon" /> <p>Facebook</p>
              </Link>
              <Link to={'https://www.instagram.com/'} className="social">
                <FaInstagram color="white" className="icon" /> <p>Instagram</p>
              </Link>
              <Link  to="https://wa.me/9820849090"
                  target="_blank"
                  rel="noopener noreferrer" className="social">
                <FaWhatsapp color="white" className="icon" /> <p>WhatsApp</p>
              </Link>
            </div>
            <div className="footer-nav-cont col-2">
              <div className="speacial-h3">
                <h3>Contact Us</h3>
              </div>
              <div className="social">
                <CgMail className="icon" /> <p>arogyayoghome@gmail.com</p>
              </div>
              <div className="social">
                <FaPhone className="icon" /> <p>+91-99200 27780</p>
              </div>
              <div className="social">
                <FaMapPin className="icon" />{" "}
                <p> pilot no. 851 first floor Arogya yoghome
                        Sawan Hall no.3
                        Oshiwara  link Rd.   Near  infinity  mall andheri west Mumbai</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="copywright">
          <p>2024 ©️ Arogya Yoghome | All rights reserved | Shree Krishna Digital Marketing</p>
        </div>
      </section>
    </>
  );
}
