import React, { useEffect, useRef, useState } from "react";
import { GrYoga } from "react-icons/gr";
import { TbYoga } from "react-icons/tb";
import "swiper/css";
import "swiper/css/pagination";
import "./About.css";
import { Reveal, Rotate, Slide } from "react-awesome-reveal";
import CountUp from "react-countup";
import { Link, useParams } from "react-router-dom";
import { trainerDetail } from "../components/Trainer";
import { MetaTags } from "react-meta-tags";


export default function About() {
  const [isInView, setIsInView] = useState(false);
  const headingRef = useRef(null);
  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => setIsInView(entry.isIntersecting),
      { threshold: 0.1 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    return () => {
      if (headingRef.current) {
        observer.unobserve(headingRef.current);
      }
    };
  }, []);
  const heading = "Personal Information".split(" ");

  const { id } = useParams();
  const trainers = trainerDetail.find((trainer) => trainer.id === Number(id));

  if (!trainers) {
    console.log('Trainers not found!')
  }
  return (
    <>
         <MetaTags>
        <title>Yoga Class Near Andheri | Arogya Yoghome LLP</title>
        <meta title="Yoga Class Near Andheri | Arogya Yoghome LLP" />
        <meta name="description" content="Best Yoga Trainner in Andheri ,Best Yoga Classes In Andheri  West, Doctor Consultation In Andheri West, Yoga In Andheri West, Yoga Class Near Andheri, Top Yoga Centre in Andheri " />
        <link rel="canonical" href="https://arogyayoghome.com/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Yoga Class Near Andheri | Arogya Yoghome LLP" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://arogyayoghome.com/" />
        <meta property="og:description" content="Discover the best Yoga Class Near Andheri at Arogya Yoghome LLP, offering holistic wellness and personalized yoga sessions." />
        <meta property="og:image" content="https://kinararesort.in/static/media/logo.146c55d2a549f20e2963.png" />
      </MetaTags>

    <div className="page-content">
      <div className="pbmit-title-bar-wrapper">
        <div className=" container">
          <div className="pbmit-title-bar-content">
            <div className="pbmit-title-bar-content-inner">
              <div className="pbmit-tbar">
                <div className="pbmit-tbar-inner container">
                  <Slide direction="left" duration={2000} delay={500}>
                    <h1 className="pbmit-tbar-title speacial-heading ">Yoga Class Near Andheri</h1>
                  </Slide>
                </div>
              </div>
              <div className="pbmit-breadcrumb">
                <Slide direction="left" duration={2000} delay={1000}>
                  <div className="pbmit-breadcrumb-inner">
                    <span>
                      <a title="" href="/" className="home">
                        <span>Home</span>
                      </a>
                    </span>
                    <span className="sep">
                      <i className="pbmit-base-icon-angle-double-right" />
                    </span>
                    <span>
                      <span className="post-root post post-post current-item">
                        About Us
                      </span>
                    </span>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Section 2 */}
      <section className="section-md">
        <div className="aboutus-custom-cont container">
          <div className="about-custom-row row g-0">
            <div className="col-md-12 col-xl-6">
              <div className="about-us-two_img">
                <div className="fid-style-2_box">
                  <div className="pbminfotech-ele-fid-style-2">
                    <div className="pbmit-fld-contents">
                      <div className="pbmit-fld-wrap">
                        <h4 className="pbmit-fid-inner">
                          <span className="pbmit-fid-before" />
                          <span className="pbmit-number-rotate numinate completed">
                            <CountUp end={235} duration={10} />
                          </span>
                        </h4>
                        <div className="pbmit-fid-sub">
                          <div className="pbmit-heading-desc">
                            Happy club members
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="pbmit-sticky-corner pbmit-bottom-left-corner">
                      <svg
                        width={30}
                        height={30}
                        viewBox="0 0 30 30"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M30 30V0C30 16 16 30 0 30H30Z" />
                      </svg>
                    </div>
                    <div className="pbmit-sticky-corner pbmit-top-right-corner">
                      <svg
                        width={30}
                        height={30}
                        viewBox="0 0 30 30"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M30 30V0C30 16 16 30 0 30H30Z" />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-xl-6">
              <div className="about-us-two_rightbox">
                <div className="pbmit-heading-subheading animation-style2">
                  <h4 className="pbmit-subtitle">About Us</h4>
                  <h2 className="pbmit-title" style={{ perspective: 400 }}>
                    <Slide direction="right" duration={2000} delay={1000}>
                      Start your healthy life today with us
                    </Slide>
                  </h2>
                  <div className="pbmit-heading-desc">
                    Start your healthy life today with us! Our expert trainers and personalized yoga sessions will guide you towards physical fitness, mental clarity, and overall wellness. Begin your journey to a healthier, more balanced life now!

                  </div>
                </div>
                <div className="about-us-two_ihbox">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="pbmit-ihbox-style-6">
                        <div className="pbmit-ihbox-headingicon">
                          <div className="pbmit-ihbox-icon">
                            <div className="pbmit-ihbox-icon-wrapper">
                              <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                                <GrYoga />
                              </div>
                            </div>
                          </div>
                          <div className="pbmit-ihbox-contents">
                            <h2 className="pbmit-element-title">
                              Changing rooms
                            </h2>
                            <div className="pbmit-heading-desc">
                            Spacious, clean changing room for yoga trainers’ convenience.
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="pbmit-ihbox-style-6">
                        <div className="pbmit-ihbox-headingicon">
                          <div className="pbmit-ihbox-icon">
                            <div className="pbmit-ihbox-icon-wrapper">
                              <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                                <TbYoga />
                              </div>
                            </div>
                          </div>
                          <div className="pbmit-ihbox-contents">
                            <h2 className="pbmit-element-title">
                              Training Programs
                            </h2>
                            <div className="pbmit-heading-desc">
                            Comprehensive training program to enhance yoga teaching skills.

                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-md">
        <div className="container">
          <div className="row">
            {trainerDetail.map((el, i) => (
              <div className="col-md-6 col-xl-4" key={el.id}>
                <div className="pbmit-ihbox-style-10">
                  <div
                    className="pbmit-ihbox-box"
                    style={{ backgroundColor: "white" }}
                  >
                    <div className="pbmit-content-wrapper">
                      <div className="pbmit-heading-desc">yoga trainer</div>
                      <h2 className="pbmit-element-title">
                        <Link
                          style={{ color: "#448c74" }}
                        // to={ `/about/traine/${el.id}` }
                        >
                          {el.name}
                        </Link>
                        <br /> Trainer
                      </h2>
                    </div>
                    <div className="pbmit-ihbox-icon">
                      <div className="pbmit-ihbox-icon-wrapper">
                        <div className="pbmit-icon-wrapper pbmit-icon-type-icon">
                          <i
                            className={`pbmit-Yoga-icon pbmit-Yoga-icon-yoga-pose-${i + 1
                              }`}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="pbmit-ihbox-box-number">
                    {String(i + 1).padStart(2, "0")}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <section>

      </section>
    </div>
    </>
  );
}


/**
 * 
 */