import React from 'react'
import CarouselBanner from './inc/CarouselBanner'
import MoreMember from './inc/MoreMember'
import HomeService from './inc/HomeService'
import HomeMarkq from './inc/HomeMarkq'
import HomeClassWork from './inc/HomeClassWork'
import HomeTween from './inc/HomeTween'
import HomeTranies from './inc/HomeTranies'
import HomeSignup from './inc/HomeSignup'
import HomeReview from './inc/HomeReview'
import HomeGallery from './inc/HomeGallery'
import HomeAbout from './inc/HomeAbout'
import MetaTags from 'react-meta-tags';

export default function Home() {
  return (
    <>
      <MetaTags>
        <title>Best Yoga Trainner in Andheri | Arogya Yoghome LLP</title>
        <meta title="Best Yoga Trainner in Andheri | Arogya Yoghome LLP" />
        <meta name="description" content="Best Yoga Trainner in Andheri ,Best Yoga Classes In Andheri  West, Doctor Consultation In Andheri West, Yoga In Andheri West, Yoga Class Near Andheri, Top Yoga Centre in Andheri " />
        <link rel="canonical" href="https://arogyayoghome.com/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Yoga Trainner in Andheri | Arogya Yoghome LLP" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://arogyayoghome.com/" />
        <meta property="og:description" content="Arogya Yoghome LLP offers the Best Yoga Trainer in Andheri, providing personalized yoga sessions for enhanced health and wellness." />
        <meta property="og:image" content="https://kinararesort.in/static/media/logo.146c55d2a549f20e2963.png" />
      </MetaTags>
      <CarouselBanner />
      <MoreMember />
      <HomeAbout />
      <HomeService />
      <HomeMarkq />
      <HomeClassWork />
      <HomeTween />
      <HomeTranies />
      <HomeGallery />
      {/* <HomeSignup /> */}
      <HomeReview />
    </>
  )
}
