import React from 'react'
import './HomeTween.css'
export default function HomeTween() {

  return (
    <div className='section d-flex text-center'>
      <h1>
        QUALIFIED <span className='bordered'>INSTRUCTORS</span>
      </h1>    </div>
  )
}
