import React from 'react'
import './Page404.css'
import { Link } from 'react-router-dom'
import { Slide } from 'react-awesome-reveal'
export default function Page404() {
  return (
    <>
      <div class="pbmit-title-bar-wrapper">
        <div class="container">
          <div class="pbmit-title-bar-content">
            <div class="pbmit-title-bar-content-inner">
              <div class="pbmit-tbar">
                <div class="pbmit-tbar-inner container">
                  <Slide direction="left" duration={2000} delay={500}>
                    <h1 style={{ color: "white" }} className="pbmit-tbar-title speacial-heading">
                      Page not Found
                    </h1>
                  </Slide>
                </div>
              </div>
              <div class="pbmit-breadcrumb">
                <Slide direction="left" duration={2000} delay={1000}>
                  <div class="pbmit-breadcrumb-inner">
                    <span>
                      <a title="" href="#" class="home">
                        <span>Yoga</span>
                      </a>
                    </span>
                    <span class="sep">
                      <i class="pbmit-base-icon-angle-double-right"></i>
                    </span>
                    <span>
                      <span class="post-root post post-post current-item">
                        {" "}
                        Page 404
                      </span>
                    </span>
                  </div>
                </Slide>
              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="error-container-404">
        <span class="four"><span class="screen-reader-text">4</span></span>
        <span class="zero"><span class="screen-reader-text">0</span></span>
        <span class="four"><span class="screen-reader-text">4</span></span>
      </section>
      <div style={{marginBottom:50}} class="link-container">
        <Link target="_blank" to={'/'} class="more-link">Back To Home</Link>
      </div></>
  )
}