import homeYoga from './home_yoga.jpeg';
import CommunityYoga from './community_yoga.jpeg'
import CorporateYoga from  './Corporate_Yoga.jpeg'
import HospitalYoga from  './Hospital_yoga.jpeg'
import H_R_yoa from  './h&R_Yoga.jpeg'
import Women_Yoga from  './Women_yoga.jpeg'
import F_C_Yoga from './F_C_yoga.jpeg'
import EmpYoga from './emp_yoga.jpeg'
import school_yoga from './shool_yoga.jpeg'
import AbroadYoga from './abroad_yoga.jpeg'
import TourstYoga from './Toursit.jpeg'
import Occupation_Yoga from './occupational.jpeg'
import Members from './members.png'
import videoImg from './videoImg.png'
import girlStatu from './girl status.png'
import expert1 from './expert1.jpg'
import expert2 from './expert2.jpg'
import expert3 from './expert3.jpg'
import expert4 from './expert4.webp'
import expert5 from './expert5.jpg'
import expert6 from './expert6.png'
import type1 from './type1.jpg'
import type2 from './type2.jpg'
import type3 from './type3.jpg'
import type4 from './type4.jpg'
import type5 from './type5.jpg'
import type6 from './type6.jpg'
import type7 from './type7.png'
import type8 from './type8.jpg'
import type9 from './type9.jpg'
import type10 from './type10.jpg'
import type11 from './type11.jpg'
import aboutimg from './about-img-01.jpg'
import logo from './logo.png'
export const imageData = {
    HomeYoga: homeYoga,
    CommunityYoga:  CommunityYoga,
    CorporateYoga: CorporateYoga,
    HospitalYoga:HospitalYoga,
    H_R_yoa:H_R_yoa,
    Women_Yoga:Women_Yoga,
    F_C_Yoga:F_C_Yoga,
    EmpYoga:EmpYoga,
    school_yoga:school_yoga,
    AbroadYoga:AbroadYoga,
    TourstYoga:TourstYoga,
    Occupation_Yoga:Occupation_Yoga,
    members: Members,
    videoImg: videoImg,
    girlStatu: girlStatu,
    expert1: expert1,
    expert2:expert2,
    expert3:expert3,
    expert4:expert4,
    expert5:expert5,
    expert6:expert6,
    type1:type1,
    type2: type2,
    type3:type3,
    type4: type4,
    type5: type5,
    type6: type6,
    type7: type7,
    type8: type8,
    type9:type9,
    type10: type10,
    type11: type11,
    aboutimg:aboutimg,
    logo:logo
};
